body {
  margin: 0;
  font-family: 'Aeonik Eurosport', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *:before, *:after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Aeonik Eurosport';
  src: url('./fonts/AeonikEurosport-Regular.woff2') format('woff2'),
  url('./fonts/AeonikEurosport-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Eurosport';
  src: url('./fonts/AeonikEurosport-Bold.woff2') format('woff2'),
  url('./fonts/AeonikEurosport-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* remove when eurosport-ui updates */
@font-face {
  font-family: 'AeonikBold';
  src: url('./fonts/AeonikEurosport-Bold.woff2') format('woff2'),
  url('./fonts/AeonikEurosport-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

#root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
